/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import axios from 'axios'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import withStyles from '@material-ui/core/styles/withStyles'

import { CustomButtonsBackground } from 'components/CustomButtons'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader.jsx'
import Section from '../../components/Section/Section'

import SimpleSlider from './comp/Slider'

// Utils
import compose from 'utils/compose'
import { numberFormatText } from 'utils/utils'

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'

import defaultGerminiConsumer from 'assets/img/GERMINI_Banners_Intitucional/GERMINI_banner_institucional_consumidor.jpg'

import defaultCvaleConsumer from 'assets/img/CVALE_Banners_Intitucional/GERMINI_banner_institucional_consumidor.jpg'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// style
import styles from './home.module.scss'

import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import {
	GetActiveTierData,
	GetConsumerExpirationPoints,
	GetTierData,
	HideLoader,
	ShowLoader,
} from 'redux/actions'
import IndicationCard from '../../components/IndicationCard'

// @Styles
import * as S from './styles'
import { Box, Skeleton } from '@mui/material'
import ProductsCategory from './comp/ProductsCategory'
import ExchangePoints from './comp/ExchangePoints'
import ProgramCurrency from './comp/ProgramCurrency'
import CurrencyTable from './comp/CurrencyTable'
import { GetMagentoReedemProduct } from '../../redux/api/products'
import { TurnOffMagentoIntegration } from '../../redux/api/user'
import ShowIntegrationModal from './comp/ShowIntegrationModal'
import { useConsumer } from '../../Context/consumerContext'
import { GetUrlCategory } from '../../redux/api/store'
import { Tiers } from './comp/Tiers'
import { getProgramTitle } from '../../utils/utils'

const Home = (props) => {
	const [showIntegrationMessage, setShowIntegrationMessage] = useState(false)
	const { GetCurrentConsumer } = useConsumer()

	const { t, generalRulesData } = props
	const [loading, setLoading] = useState(true)
	const [products, setProducts] = useState([])
	const [categoryItems, setCategoryItems] = useState(false)
	const [activeSlide, setActiveSlide] = useState(0)
	const [url, setUrl] = useState('')
	const slidesToScrollValue = 3
	const slidesToShowValue = 4
	const [consumerProgress, setConsumerProgress] = useState([])
	const [tierGroupCategorization, setTierGroupCategorization] = useState({
		category: '',
		currentLevel: '',
	})
	const [bannersDisplayTime, setBannersDisplayTime] = useState(5)
	const [bannerInfo, setBannerInfo] = useState([])
	const [themeColor, setThemeColor] = useState({})

	const [tiersOrdered, setTiersOrdered] = useState(null)

	useEffect(() => {
		setThemeColor(GetThemeColorFromStorage())

		props.ShowLoader(<Loader />)
		props.GetActiveTierData().then((rest) => {
			if (_.isEmpty(props.userData)) {
				GetCurrentConsumer()
			} else {

				let tiers = rest.tiers
				if (!_.isEmpty(tiers)) {
					changeCategoryImg(props.userData.points, tiers)
				}
			}
		})

		axios
			.get(`/Participants/Tier/GetCurrentTierByConsumer`)
			.then((response) => {

				if (response?.data) {
					const { data } = response
					setTierGroupCategorization({
						category: data?.tierGroupCategorization || '',
						currentLevel: data?.currentLevel || '',
					})
					setConsumerProgress(response.data)
				}
			})

		axios
			.get(`/Dashboard/GetBannersByConsumer`)
			.then((response) => setBannerInfo(response.data))
			.finally(() => setLoading(false))
	}, [])

	useEffect(() => {
		if (bannerInfo !== null) {
			bannerInfo.forEach((item) => {
				if (item.bannerType.value === 'CAROUSEL') {
					setBannersDisplayTime(item.displayTime)
				}
			})
		}
	}, [bannerInfo])

	const getAllProducts = async () => {
		try {
			const response = await GetMagentoReedemProduct()
			const url = await GetUrlCategory()

			const urlSplited = url?.data?.split('br/')

			if (urlSplited?.length > 1) {
				setUrl(urlSplited[0] + 'br/')
			} else {
				console.error('A URL não foi dividida corretamente.')
			}

			setProducts(response.data)
		} catch (error) {
			console.error('Erro ao buscar produtos:', error)
		}
	}

	useEffect(() => {
		getAllProducts()
	}, [])

	useEffect(() => {
		if (props.generalRulesData) {
			props.HideLoader()
		}
	}, [props.generalRulesData])

	useEffect(() => {
		if (props.userData && tiersOrdered && !categoryItems) {
			props.HideLoader()
			changeCategoryImg(props.userData.points, tiersOrdered)
		}
	}, [props.userData, categoryItems, props])

	useEffect(() => {
		if (props.activeTierData) {
			props.HideLoader()
			let tiers = _.orderBy(
				props.activeTierData,
				['scoreStart'],
				['cresc'],
			)
			setTiersOrdered(tiers)
		}
	}, [props.activeTierData])

	useEffect(() => {
		if (props.walletExpirationPointsData) {
			props.HideLoader()
		}
	}, [props.walletExpirationPointsData])

	useEffect(() => {
		if (props.userDataFail) {
			props.HideLoader()
			console.error('=userDataFail=> ', props.userDataFail)
		}
	}, [props.userDataFail])

	const handleLink = (item) => {
		const link = item[1]
		const id = item[2]
		const click = true

		if (link) {
			const url = link.startsWith('http') ? link : `https://${link}`
			axios.put(`/Banner/MetricsBannersCount/${id}?click=${click}`)
			window.open(url, '_blank')
		}
	}

	const changeCategoryImg = (points, tiers) => {
		const actives = _.orderBy(tiers, ['tierOrder', true])

		const items = actives.map((t) => ({
			url: t.iconUrl,
			categoryName: t.name,
			scoreStart: t.scoreStart,
			active: points >= t.scoreStart,
		}))

		setCategoryItems(items)
	}

	let bannerPositionFiveInfo = []
	if (!_.isEmpty(bannerInfo)) {
		bannerPositionFiveInfo = bannerInfo.map((item) => {
			if (
				item.positionOnScreen.value === 'CONSUMER_CAROUSEL_POSITION_1'
			) {
				return [item.imageUrl, item.link, item.id]
			}
			return null
		})
	}

	let bannerPositionSixInfo = []
	if (!_.isEmpty(bannerInfo)) {
		bannerPositionSixInfo = bannerInfo.map((item) => {
			if (item.positionOnScreen.value === 'CONSUMER_FIXED_POSITION_2') {
				return [item.imageUrl, item.link, item.id]
			}
			return null
		})
	}

	function LeftNavButton(props) {
		const { style, onClick } = props
		return (
			<S.LeftButton
				className={'slick-arrow'}
				sx={{ ...style }}
				onClick={onClick}
			>
				{activeSlide > 0 && '‹'}
			</S.LeftButton>
		)
	}

	function RightNavButton(props) {
		const { style, onClick } = props
		return (
			<S.RightButton
				className={'slick-arrow'}
				sx={{ ...style }}
				onClick={onClick}
			>
				{activeSlide + slidesToShowValue < categoryItems.length && '›'}
			</S.RightButton>
		)
	}

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: slidesToShowValue,
		slidesToScroll: slidesToScrollValue,
		prevArrow: <LeftNavButton />,
		nextArrow: <RightNavButton />,
		handleProgress: 'progress',
		afterChange: (current) => setActiveSlide(current),
	}

	const handleClose = () => {
		setShowIntegrationMessage(!showIntegrationMessage)
		TurnOffMagentoIntegration(props.userData?.cpf)
	}

	useEffect(() => {
		if (props.userData?.showIntegrationMessage) {
			setShowIntegrationMessage(true)
		}
	}, [props.userData?.showIntegrationMessage])



	return (
		<div style={{ marginRight: '-13px' }}>
			<ShowIntegrationModal
				open={showIntegrationMessage}
				handleClose={handleClose}
			/>
			<DocumentTitle title={getProgramTitle('Dashboard')} />
			<GridContainer>
				{/* banner 1 */}
				<GridItem xs={12} sm={6} md={6} lg={6}>
					{!_.isEmpty(_.compact(bannerPositionFiveInfo)) ? (
						<SimpleSlider
							bannerInfo={_.compact(bannerPositionFiveInfo)}
							bannersDisplayTime={bannersDisplayTime}
						/>
					) : loading ? (
						<Skeleton
							variant="rectangular"
							width="100%"
							height={150}
						/>
					) : (
						<img
							alt="Banner 1"
							src={
								process.env.REACT_APP_CVALE_IDENTITY === 'true'
									? defaultCvaleConsumer
									: defaultGerminiConsumer
							}
							style={{ paddingBottom: '30px' }}
							width="100%"
						/>
					)}
				</GridItem>
				{/* banner 2 */}
				<GridItem xs={12} sm={6} md={6} lg={6}>
					{!_.isEmpty(_.compact(bannerPositionSixInfo)) ? (
						<>
							{bannerPositionSixInfo?.map((item, index) => {
								if (item !== null) {
									return (
										<div
											key={index.toString()}
											onClick={() => handleLink(item)}
										>
											<img
												src={item[0]}
												alt="Banner 1"
												width="98%"
												style={{
													marginBottom: '30px',
													height: '150px',
													objectFit: 'cover',
													cursor: 'pointer',
												}}
											/>
										</div>
									)
								}
								return null
							})}
						</>
					) : loading ? (
						<Skeleton
							variant="rectangular"
							width="100%"
							height={150}
						/>
					) : (
						<img
							alt="Banner 2"
							src={
								process.env.REACT_APP_CVALE_IDENTITY === 'true'
									? defaultCvaleConsumer
									: defaultGerminiConsumer
							}
							style={{ paddingBottom: '30px' }}
							width="100%"
						/>
					)}
				</GridItem>
			</GridContainer>

			<GridContainer>
				{/* LINE 1.1 */}
				{props.generalRulesData &&
					props.generalRulesData?.allowProgramLevels && (
						<Tiers
							t={t}
							consumerProgress={consumerProgress}
							categoryItems={categoryItems}
							loading={loading}
							themeColor={themeColor}
							settings={settings}
							tierGroupCategorization={tierGroupCategorization}
							generalRulesData={props.generalRulesData}
						/>
					)}

				{/* LINE 3 */}
				<GridItem xs={12} sm={12} md={6}>
					<CurrencyTable loading={loading} />
				</GridItem>

				{/* LINE 4 */}
				{generalRulesData.allowIndications && (
					<GridItem xs={12} sm={12} md={6}>
						<Section title={t('SECTION_INDICATION')} />
						<IndicationCard></IndicationCard>
					</GridItem>
				)}

				{/* LINE 5 */}
				{/* <ProgramCurrency /> */}

				{/* LINE 6 */}
				<ProductsCategory />

				{/* LINE 7 */}
				{/* {generalRulesData?.allowEcommerce && (
					<GridItem xs={12} sm={12} md={12}>
						<ExchangePoints products={products} url={url} />
					</GridItem>
				)} */}
			</GridContainer>
		</div>
	)
}

Home.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return {
		tierData: state.tier.tierData,
		walletExpirationPointsData: state.wallet.walletExpirationPointsData,

		activeTierData: state.tier.activeTierData,
		activeTierDataFailed: state.tier.activeTierDataFailed,
		generalRulesData: state.generalRules.generalRulesData,

		userData: state.users.userData,
		userDataFail: state.users.userDataFail,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetActiveTierData,
			GetConsumerExpirationPoints,
			GetTierData,
			HideLoader,
			ShowLoader,
		},
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(S.inputLabelPropsStyles),
	withStyles(S.inputPropsStyles),
	withStyles({ dashboardStyle }),
	withTranslation(),
)(Home)
